import React, { useState } from 'react'
import '../styles/section-3.scss'

import Modal from 'react-modal';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

import { gsap } from 'gsap';

import { ScrollTrigger } from "gsap/ScrollTrigger";

import { Canvas } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei';

import fetchWithTimeout from '../utils/fetchWithTimeout';

//images
import warning from '../images/warning.svg';

Modal.setAppElement('#root');
Modal.defaultStyles.overlay.backgroundColor = 'rgba(0, 0, 0, 0.75)';

gsap.registerPlugin(ScrollTrigger);

const Section3 = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [emailError, setEmailError] = useState(null);

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const gltf = useGLTF('/cereal.glb');
    
    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };    

    const submitEmail = async (values) => {
        setIsSubmitting(true);
        setEmailError(null);

        const response = await fetchWithTimeout(`/.netlify/functions/submit-email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({email: values.emailAddress})
        });
       
        if(response.status !== 200) {
            setIsSubmitting(false);
            setEmailError('Something went wrong, please try again')
            throw new Error(`Server responded with ${response.status}`);
        } 
    
        setIsSubmitting(false);
        closeModal();
    };
    
    const EmailSchema = Yup.object().shape({
        emailAddress: Yup.string().email('Invalid email').required('Email is required')                     
    })    

    return (
        <section id='section-3'>            

            <div className='product-container'>

                <div id='canvas-container'>

                    <Canvas camera={{ position: [0, 1, 8] }}>
                        <ambientLight intensity={2.25} />
                        
                        <primitive
                            object={gltf.scene}
                            position={[0, 0, 0]}        
                        />

                        <OrbitControls 
                            target={[0, 2.5, 0]} 
                            autoRotate={true}
                            enableZoom={false}
                            enablePan={false}
                            />

                    </Canvas>

                </div>
                
                <div className='product-detail-container'>
                    <p className='title'>Caff Crunch (450g)<br />£5.00</p>

                    <p className='detail'>Savoury</p>
                    <p className='detail'>Like a fryup</p>
                    <p className='detail'>But cereal</p>
                    <p className='detail'>Limited availability</p>

                    <div className='soldout-container'>
                        <img className='soldout-warning' src={warning} alt="Warning Sign" />
                        <button className='mail-button' onClick={openModal}>Notify When Back In Stock</button>                        
                    </div>

                </div>

            </div>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className='email-modal'>

                    <img src={warning} alt="Warning Sign" />

                    <p>Register your interest for the next restock</p>

                    <Formik
                        initialValues={{ 
                            emailAddress: '',
                        }}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validationSchema={EmailSchema}
                        onSubmit={(values) => submitEmail(values)}>

                        {() => (    
                            <Form>   
                                { emailError && 
                                    <p className='error-message'>{emailError}</p>
                                }

                                <Field type="email" name="emailAddress" placeholder="BOB@THEBUILDER.COM" />

                                <button disabled={isSubmitting} type="submit">Get Notified</button>
                            </Form>
                        )}

                    </Formik>

            </Modal>
                  

        </section>
    )
}

export default Section3